<template>

  <div class="container-fluid">
    <div v-if="mapDetails===null || markers.length <0">
      <div class="alert alert-info">Loading...</div>
    </div>
    <div class="row gx-0">

      <div class="col-md-9">
        <GMapMap
            api-key="AIzaSyD7-un0s7EHnFMh-yNDKC5xMi6pZE2cYEw"
            style="width: 100%; height: 100vh"
            :center="mapCenter"
            :zoom="mapZoom"
            class="sticky-top"
            ref="searchMap"
            :options="mapStyle"
            :map-type-id="mapType"
        >
          <GMapPolygon  v-for="(r) in shapes" :key="r.id"
                        :paths="r.path" :options="r.style" />

            <GMapMarker v-for="(r) in translated" :key="r.id"
                      :icon="{url: `${mediaBase}/${icons[r.icon_id]}`, scaledSize: {width: 30, height: 30},}"
                      :position="{lat: parseFloat(r.lat), lng: parseFloat(r.lng)}"
                      @click="openMarker(r.id)">
            <GMapInfoWindow
                :closeclick="true"
                @closeclick=" openedMarkerID===r.id?openMarker(null):null"
                :opened=" openedMarkerID === r.id">
              <div class="card border-0" style="max-width: 300px;">
                <a :href="`${mediaBase}/${r.photo}`" target="_blank" v-if="r.photo">
                  <img :src="`${mediaBase}/${r.photo}`" :alt="r.name" class="card-img-top">
                </a>
                <div class="card-body">
                  <p class="fw-bold lead">{{ r.name }}</p>
                  <div v-html="r.description"></div>

                  <div class="row">
                    <div class="col-6" v-if="r.photo2">
                      <a :href="`${mediaBase}/${r.photo2}`" target="_blank">
                        <img :src="`${mediaBase}/${r.photo2}`" :alt="r.name" class="w-100 img-thumbnail">
                      </a>
                    </div>
                    <div class="col-6" v-if="r.photo3">
                      <a :href="`${mediaBase}/${r.photo3}`" target="_blank">
                        <img :src="`${mediaBase}/${r.photo3}`" :alt="r.name" class="w-100 img-thumbnail">
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </GMapInfoWindow>
          </GMapMarker>
        </GMapMap>
      </div>
      <div class="col-md-3">
        <div style="height: 100vh; overflow-x: auto">
          <div class="list-group list-group-flush">
            <div class="list-group-item">
              <div>
                <input type="search" class="form-control" v-model="searchKeyword" placeholder="search...">
              </div>
            </div>
            <div class="list-group-item d-flex justify-content-start" :class="{'active': openedMarkerID === m.id}"
                 v-for=" m in searchResult" :key="m.id" @click.prevent="openMarker(m.id)"
                 style="cursor: pointer">
              <img :src="`${mediaBase}/${icons[m.icon_id]}`" style="height: 30px; margin-right: 10px;" :alt="m.name">
              <span>{{ m.name }}</span>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
export default {
  name: "MapView",
  data() {
    return {
      language: 'en',
      mediaBase: 'https://cmp.azinweb.net',
      mapDetails: null,
      markers: [],
      shapes: [],
      icons: {},
      openedMarkerID: null,
      searchKeyword: '',
      mapStyle: {
        styles: [
          {
            "elementType": "labels",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "administrative.neighborhood",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          }
        ]
      }
    }
  },
  watch: {
    openedMarkerID(newVal) {
      console.log(newVal);
    }
  },
  computed: {
    mapCenter() {
      if (this.mapDetails == null) {
        return {lat: 0, lng: 0};
      } else {

        return {lat: parseFloat(this.mapDetails.center_lat), lng: parseFloat(this.mapDetails.center_lng)}
      }
    },
    mapZoom() {
      if (this.mapDetails == null) {
        return 10;
      } else {
        return parseInt(this.mapDetails.zoom);
      }
    },mapType() {
      if (this.mapDetails == null) {
        return 'satellite';
      } else {
        return this.mapDetails.map_type;
      }
    },
    translated() {

      let list = [];

      this.markers.forEach(m => {
        if (this.language !== 'ru') {
          if (m.name_en !== '') {
            m.name = m.name_en;
          }
          if (m.description_en !== '') {
            m.description = m.description_en;
          }
        }
        list.push(m);
      })
      return list;

    },
    searchResult() {
      return this.translated.filter(m => m.name.toLowerCase().indexOf(this.searchKeyword.toLowerCase()) > -1);
    }
  },
  methods: {

    getMapDetails() {
      fetch("https://cmp.azinweb.net/api/external/search?element=189&select[]=name&select[]=style&select[]=map_type&select[]=description&select[]=zoom&select[]=center_lat&select[]=center_lng&filters[id]=" + this.$route.params.id)
          .then(res => res.json())
          .then(data => {
            if (data.data.length > 0) {
              this.mapDetails = data.data[0];
              this.mapStyle.styles = JSON.parse(this.mapDetails.style);
              this.loadMarkers(this.mapDetails.id);
              this.loadShapes(this.mapDetails.id);

            }
          })
    },

    loadMarkers(mapId) {
      fetch("https://cmp.azinweb.net/api/external/search?element=190&select[]=icon_id&select[]=name&select[]=name_en&select[]=description&select[]=description_en&select[]=zoom&select[]=lat&select[]=lng&select[]=photo&select[]=photo2&select[]=photo3&filters[map_id]=" + mapId)
          .then(res => res.json())
          .then(data => {
            if (data.data.length > 0) {
              this.markers = data.data;
            }
          })
    },

    loadShapes(mapId) {
      fetch("https://cmp.azinweb.net/api/external/search?element=196&select[]=name&select[]=path&select[]=style&filters[map_id]=" + mapId)
          .then(res => res.json())
          .then(data => {
            if (data.data.length > 0) {
              this.shapes = [];
              data.data.forEach(d=>{

                d.path = JSON.parse(d.path);
                d.style = JSON.parse(d.style);
                this.shapes.push(d);
              });

            }
          })
    },
    openMarker(id) {
      this.openedMarkerID = id
    }

  },

  mounted() {

    if (this.$route.query.marker > 0) {
      this.openedMarkerID = this.$route.query.marker;
    }

    if (this.$route.query.language) {
      this.language = this.$route.query.language;
    }

    fetch("https://cmp.azinweb.net/api/external/search?element=191")
        .then(res => res.json())
        .then(data => {
          if (data.data.length > 0) {
            data.data.forEach(e => {
              this.icons[e.id] = e.icon;
            })
          }
          this.getMapDetails();
        })


  }

}
</script>

<style scoped>

</style>