import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";


import bootstrap from 'bootstrap';
import VueGoogleMaps from '@fawmi/vue-google-maps'

createApp(App)
    .use(store)
    .use(router)
    .use(bootstrap)
    .use(VueGoogleMaps, {
        load: {
            key: 'AIzaSyD7-un0s7EHnFMh-yNDKC5xMi6pZE2cYEw',
            libraries: "places"
        },
    })
    .mount("#app");
