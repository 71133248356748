<template>
  <div class="home">
    <div class="list-group">
      <router-link :to="{name: 'map', params:{id: m.id}, query:{language:'ru'}}" class="list-group-item" v-for="m in maps " :key="m.id">{{m.name}} (RU)</router-link>
      <div class="list-group-item-dark">&nbsp;</div>
      <router-link :to="{name: 'map', params:{id: m.id}, query:{language:'en'}}" class="list-group-item" v-for="m in maps " :key="m.id">{{m.name}} (EN)</router-link>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "HomeView",
  data(){
     return {
       maps: []
     }
  },
  components: {},

  mounted() {

    fetch("https://cmp.azinweb.net/api/external/search?element=189&select[]=name&select[]=description&select[]=zoom&select[]=center_lat&select[]=center_lng")
        .then(res => res.json())
        .then(data => {
          this.maps = data.data;
        });

  }
};
</script>
